import { LanguageTile } from '@/Components/Reusables/LanguageTile/LanguageTile';
import React from 'react';
// import { gql, useQuery } from '@apollo/client';
import classes from './LanguagesPage.module.scss';

const languages = [
  { id: 'tabla', name: 'Tabla' },
  { id: 'english', name: 'English' },
  { id: 'hindi', name: 'Hindi' }
];

// const GET_LANGUAGES_QUERY = gql`
//   query Query {
//     languages {
//       languageId
//       name
//     }
//   }
// `;

export const LanguagesPage: React.FC = () => {
  // const { loading, error, data } = useQuery(GET_LANGUAGES_QUERY);

  // if (error) return <p>Error Getting Data</p>;
  // if (loading) return <p>Loading</p>;
  // console.log(data);

  return (
    <main>
      <div>current Language: Tabla</div>
      <h3>Which Language do you want to Learn?</h3>
      <ul className={classes.grid}>
        {/* <LanguagesList languages={LANGUAGES} /> */}
        {languages.map((language) => (
          <li className={classes.card} key={language.id}>
            <LanguageTile link={language.id} title={language.name} />
          </li>
        ))}
      </ul>
      {/* <ul>
        {data.languages.map((language: [], i: number) => (
          <li key={i}>
            <LanguageTile link={language.languageId} title={language.name} />
          </li>
        ))}
      </ul> */}
    </main>
  );
};

//Choose Languase and save in Redux
