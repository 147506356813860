import React from 'react';
import { NavItem } from '../NavItem';

export const TopNav: React.FC = () => {
  return (
    <>
      <NavItem link="/" page="Home" />
      <NavItem link="/langauges" page="Langauges" />
      <NavItem link="#" page="Games" />
    </>
  );
};
