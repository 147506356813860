import React from 'react';
import ReactDOM from 'react-dom';
import { TopNav } from './TopNav';
import classes from './ModalTopNav.module.scss';

// interface ModalProps {
//   onBackdropclick: () => void;
// }

export const ModalTopNav: React.FC = () => {
  return ReactDOM.createPortal(
    <div className={classes.modalTopNav}>
      <ul className={classes.nav__top_modal}>
        <TopNav />
      </ul>
    </div>,
    document.querySelector('#modalTopNav')!
  );
};
