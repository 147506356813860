import React from 'react';
import { Link } from 'react-router-dom';
import classes from './LanguageTile.module.scss';

interface ChildProps {
  link: string;
  title: string;
}

export const LanguageTile: React.FC<ChildProps> = ({ link, title }) => {
  return (
    <Link className={classes.cardlink} to={`/langauges/${link}`}>
      <div>{title}</div>
    </Link>
  );
};
