import React from 'react';
import { useParams } from 'react-router-dom';
import classes from './LanguagePage.module.scss';
import { WordWallTile } from '@/Components/Reusables/WordWallTile/WordWallTile';

const Languages = [
  { id: 'tabla', name: 'Tabla' },
  { id: 'english', name: 'English' },
  { id: 'hindi', name: 'Hindi' }
];
const wordWalls = [
  { id: 'tabla1', name: 'Tabla 1', abbreviation: 'L1', stortDescription: 'This is the first lesson for learing the Table' },
  { id: 'tabla2', name: 'Tabla 2', abbreviation: 'L2', stortDescription: 'This is the second lesson for learing the Table' },
  { id: 'tabla3', name: 'Tabla 3', abbreviation: 'L3', stortDescription: 'This is the third lesson for learing the Table' }
];

export const LanguagePage: React.FC = (props) => {
  const params = useParams();
  // const navigate = useNavigate();

  const language = Languages.find((language) => language.id === params.langauge);
  console.log(language);

  return (
    <div>
      <h1>{language && language.name}</h1>
      {/* <button onClick={() => navigate('/langauges')}>Choose a New Language</button> */}
      <h2>WordWalls</h2>
      <ul className={classes.grid}>
        {wordWalls.map((wordWall) => (
          <li className={classes.card} key={wordWall.id}>
            <WordWallTile link={wordWall.id} title={wordWall.name} abbreviation={wordWall.abbreviation} stortDescription={wordWall.stortDescription} />
          </li>
        ))}
      </ul>
    </div>
  );
};
