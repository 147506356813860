import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './TopBar.module.scss';
import { ToggleModalTopNav } from './TopNav/ToggleModalTopNav';
import { TopNav } from './TopNav/TopNav';

export const TopBar: React.FC = () => {
  return (
    <header className={classes.topbar}>
      <NavLink to="/" className={classes.topbar__logo__box}>
        <h2 className={classes.logotext}>Scrambled</h2>
      </NavLink>
      <nav className={classes.topnav}>
        <ul className={classes.nav__top}>
          <TopNav />
        </ul>
        <ToggleModalTopNav />
      </nav>
    </header>
  );
};
