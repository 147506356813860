import React from 'react';
// import { NavLinks } from './MainNav/NavLinks';
import classes from './Layout.module.scss';
import { Outlet } from 'react-router-dom';

export const MainLayout: React.FC = (props) => {
  return (
    <div className={classes.layout}>
      <Outlet />
    </div>
  );
};
