import React, { useState } from 'react';
// import useToggle from '@/Components/Hooks/useToggle';
import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import classes from './ToggleModalTopNav.module.scss';
import { ModalTopNav } from './ModalTopNav';

export const ToggleModalTopNav: React.FC = () => {
  const [showNav, setShowNav] = useState(true);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className={classes.toggle}>
      <div onClick={toggleNav}>
        {showNav ? (
          <BiMenuAltRight />
        ) : (
          <>
            <AiOutlineClose />
            <ModalTopNav />
          </>
        )}
      </div>
    </div>
  );
};
