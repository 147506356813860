import React from 'react';
import { Link } from 'react-router-dom';
import classes from './WordWallTile.module.scss';

interface ChildProps {
  link: string;
  title: string;
  abbreviation: string;
  stortDescription: string;
}

export const WordWallTile: React.FC<ChildProps> = ({ link, title, abbreviation, stortDescription }) => {
  return (
    <Link className={classes.cardlink} to={`/langauges/${link}`}>
      <div>
        <p className={classes.Abbreviation}>{abbreviation}</p>
      </div>
      <div>
        <p className={classes.title}>{title}</p>
        <p>{stortDescription} </p>
      </div>
      <p className={classes.count}>15</p>
    </Link>
  );
};
