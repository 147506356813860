import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavItem.module.scss';

interface ChildProps {
  link: string;
  page: string;
}

export const NavItem: React.FC<ChildProps> = ({ link, page }) => {
  return (
    <li className={classes.nav__item}>
      <NavLink className={classes.nav__link} to={link}>
        {page}
      </NavLink>
    </li>
  );
};
