import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { Home } from '@/Components/Home';
import { LanguagesPage } from '@/Components/Pages/Languages/LanguagesPage';
import { LanguagePage } from '@/Components/Pages/Language/LanguagePage';
// import { GameLayout } from '@/Components/Layouts/GameLayout';
import { NotFound } from '@/Components/Pages/Functional/NotFound';
import { TopBar } from '@/Components/Layouts/Menu/TopBar';
import { MainLayout } from './Components/Layouts/MainLayout';

const App: React.FC = () => {
  return (
    <div>
      <TopBar />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/langauges" element={<LanguagesPage />} />
          <Route path="/langauges/:langauge" element={<LanguagePage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* <Route path="/:langauge" element={<Navigate replace to="/" />} /> */}
      </Routes>
    </div>
  );
};

export default App;
